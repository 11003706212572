import styled from "styled-components"
import Grid from "./grid"
import Text from "./text"

export const Container = styled(Grid.Item)``
Container.defaultProps = {
  gridColumn: "intro-start / intro-end",
  mt: ["y.mobile.4.0", "y.desktop.4.0"],
  mb: ["y.mobile.4.0", "y.desktop.4.0"],
}

export const Label = styled(Text.Span)`
  letter-spacing: 0.1rem;
  text-transform: uppercase;
`

Label.defaultProps = {
  fontFamily: "mono",
  fontWeight: [4, 4],
  fontSize: ["mobile.-1", "desktop.0"],
  lineHeight: ["mobile.1.0", "desktop.1.0"],
  color: "black.6",
}

export const IntroText = styled(Text.H3)``

IntroText.defaultProps = {
  mt: ["y.mobile.0.5", "y.desktop.0.5"],
  fontFamily: "sansSerif",
  fontWeight: [4, 4],
  fontSize: ["mobile.0", "desktop.2"],
  lineHeight: ["mobile.1.5", "desktop.2.0"],
  color: "black.2",
}
