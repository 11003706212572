import styled from "styled-components"
import { compose, color, space } from "styled-system"
import Img from "gatsby-image"
import Text from "./text"

export const Image = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 300ms ease-in-out;
`

export const Container = styled.div`
  position: relative;
  height: 0;
  padding-top: 56.25%;

  ${compose(color, space)}

  &:hover ${Image} {
    transform: scale3d(1.1, 1.1, 1.1);
  }
`
Container.defaultProps = {
  bg: "black.9",
  mb: ["y.mobile.2.5", 0],
}

export const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const Title = styled(Text.H6)``

Title.defaultProps = {
  as: "h3",
  fontFamily: "sansSerif",
  ml: ["x.3", 0],
  my: ["y.mobile.0.5"],
}
