import styled from "styled-components"
import Grid from "./grid"

export const Container = styled(Grid.Item)`
  display: grid;
`
Container.defaultProps = {
  bg: "black.0",
  gridColumn: "full-start / full-end",
  gridColumnGap: [0, "2.4rem", "3.2rem"],
  gridTemplateColumns: [
    "[client0-start] 2fr [client0-end] 3.2rem [client1-start] 2fr [client1-end] 3.2rem [client2-start] 2fr [client2-end]",
    "1fr [client0-start] 1fr 1fr [client0-end] 1fr [client1-start] 1fr 1fr [client1-end] 1fr [client2-start] 1fr 1fr [client2-end] 1fr",
    "repeat(3, 1fr) [client0-start] 1fr 1fr [client0-end] 1fr [client1-start] 1fr 1fr [client1-end] 1fr [client2-start] 1fr 1fr [client2-end] repeat(3, 1fr)",
  ],
  px: ["2.4rem", 0],
  py: "8.4rem",
}

export const Inner = styled(Grid.Item)`
  position: relative;
  align-self: center;
  padding-top: 100%;
`

export const Client = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  transform: translate3d(0, -20px, 0);
  visibility: hidden;
  opacity: 0;
  transition: all 300ms ease-in-out;
  transition-delay: ${(props) => props.delay}ms;
  user-select: none;

  &.active {
    transform: translate3d(0, 0, 0);
    visibility: visible;
    opacity: 1;
  }
`
