import React from "react"
import { graphql } from "gatsby"
import get from "lodash.get"
import { withPreview } from "gatsby-source-prismic"

import SEO from "../components/seo"
import Grid from "../components/grid"
import Hero from "../components/hero"
import Intro from "../components/intro"
import Projects from "../components/projects"
import Keywords from "../components/keywords"
import Clients from "../components/clients"
import PageWrapper from "../components/page-wrapper"

export const query = graphql`
  query Landing {
    site {
      siteMetadata {
        title
      }
    }

    allPrismicHome {
      edges {
        node {
          prismicId
          data {
            showreel {
              embed_url
              provider_name
            }
            fallback_image {
              alt
              copyright
              fluid(maxWidth: 2048) {
                ...GatsbyPrismicImageFluid
              }
            }
            projects {
              project {
                document {
                  ... on PrismicProject {
                    id
                    uid
                    data {
                      name
                      cover_image {
                        fluid(maxWidth: 1300) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      storyline {
                        html
                        text
                        raw
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    allPrismicCategory {
      edges {
        node {
          uid
          data {
            name
          }
        }
      }
    }

    allPrismicClient {
      edges {
        node {
          id
          data {
            name
            image {
              fluid(maxWidth: 300) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  }
`

const IndexPage = (props) => {
  const page = get(props, `data.allPrismicHome.edges[0].node.data`)
  const clients = get(props, `data.allPrismicClient.edges`)
  const categories = get(props, `data.allPrismicCategory.edges`)

  if (!page) return null
  const projects = page.projects.map((item) => item.project.document)

  return (
    <PageWrapper name="index">
      <SEO title="Home" />
      <Grid>
        <Hero data={page} />
        <Intro />
        <Projects data={projects} />
        <Keywords data={categories} />
        <Clients data={clients} />
      </Grid>
    </PageWrapper>
  )
}

export default withPreview(IndexPage)
