import React, { useRef } from "react"
import { useMouseHovered } from "react-use"
import { Container, Inner } from "./flying-text.styles"

export default ({ active, children }) => {
  const containerRef = useRef(null)
  const state = useMouseHovered(containerRef, {
    bound: true,
    whenHovered: false,
  })

  if (!active) return null

  return (
    <Container ref={containerRef}>
      <Inner
        style={{
          position: "absolute",
          top: `${state.elY}px`,
          left: `${state.elX}px`,
        }}
      >
        <span>{children}</span>
      </Inner>
    </Container>
  )
}
