import React, { useState, useEffect, useRef } from "react"
import { motion } from "framer-motion"
import { useInView } from "react-intersection-observer"
import Player from "@vimeo/player"

import {
  Container,
  VideoContainer,
  Vimeo,
  FallbackImg,
  Controls,
} from "./hero.styles"
import getVideoIdFromEmbedUrl from "../utils/getVideoIdFromEmbedUrl"
import Play from "./play"

export default ({ data }) => {
  const { showreel, fallback_image } = data
  const videoId = getVideoIdFromEmbedUrl(
    showreel.provider_name,
    showreel.embed_url
  )

  const [container, inView] = useInView({ threshold: 0.5 })

  const [playerReady, setPlayerReady] = useState(false)
  const [backgroundPlay, setBackgroundPlay] = useState(true)
  
  const posterAnimation = {
    visible: { opacity: 1, transition: { duration: 0.35 } },
    hidden: { opacity: 0, transition: { duration: 0.35 } },
  }

  const controlsAnimation = {
    show: { opacity: 1, transition: { duration: 0.35 } },
    hide: { opacity: 0, transition: { duration: 0.35 } },
  }

  const player = useRef()
  const video = useRef()

  const buffered = () => {
    setPlayerReady(true)
  }

  useEffect(() => {
    if (!video.current) return
    player.current = new Player(video.current)
    player.current.on("bufferend", buffered)
    ;() => {
      if (player.current) {
        player.current.off("buffered", buffered)
        player.current.destroy()
      }
    }
  }, [video])

  useEffect(() => {
    if (!player.current) return
    if (backgroundPlay) return
    //throttle this calls
    inView ? player.current.play() : player.current.pause()
  }, [inView])

  const handlePlay = () => {
    setBackgroundPlay(false)
  }

  return (
    <Container ref={container}>
      <VideoContainer>
        <motion.div
          initial="visible"
          animate={playerReady ? "hidden" : "visible"}
          variants={posterAnimation}
        >
          <FallbackImg
            fluid={fallback_image.fluid}
            alt={fallback_image.alt}
            loading="eager"
          />
        </motion.div>
        {showreel && (
          <>
            <Vimeo
              ref={video}
              src={`https://player.vimeo.com/video/${videoId}?background=${
                backgroundPlay ? "1" : "0"
              }&autoplay=1&loop=${backgroundPlay ? "1" : "0"}&byline=0&title=0`}
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              allow="autoplay; fullscreen"
            />

            <Controls
              initial="hide"
              animate={playerReady ? "show" : "hide"}
              variants={controlsAnimation}
              disabled={!backgroundPlay}
              onClick={handlePlay}
            >
              <Play active={backgroundPlay} />
            </Controls>
          </>
        )}
      </VideoContainer>
    </Container>
  )
}
