import styled from "styled-components"
import { compose, space, layout, flexbox, color, system } from "styled-system"
import Link from "gatsby-link"
import Grid from "./grid"

const cursorDecoration = system({
  cursor: { property: "cursor" },
})

export const Container = styled(Grid.Item)`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  box-sizing: border-box;
`
Container.defaultProps = {
  gridColumn: ["full-start / full-end", "main-start / main-end"],
  mr: [0, 0, "-3.2rem"],
  pl: ["x.0", "3.2rem"],
}

export const Item = styled.div`
  ${compose(layout, space, flexbox, color, cursorDecoration)}
`
Item.defaultProps = {
  as: Link,
  flex: ["100% 0 0", "calc(50% - 3.2rem) 0 0"],
  mr: ["x.0", "x.4"],
  mb: "x.4",
  cursor: ["pointer", "none"],
}
