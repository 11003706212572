import React from "react"
import Text from "./text"
import { Container, Label, IntroText } from "./intro.styles"

export default () => (
  <Container>
    <Label>Selected Work</Label>
    <IntroText>
      Hier eine Kostprobe aus unseren bisherigen Arbeiten in den Bereichen
      Fiction, Advertisment, Music und Documentary
    </IntroText>
  </Container>
)
