import React, { useLayoutEffect } from "react"
import { useViewportScroll } from "framer-motion"
import { Container, Item } from "./projects.styles"

import Project from "./project"

export default ({ data }) => {
  const { scrollY } = useViewportScroll()

  useLayoutEffect(() => {
    window.scrollTo(0, scrollY.prev)
  }, [data.length])

  return (
    <Container>
      {data.map((project) => (
        <Item key={project.id} to={`/portfolio/${project.uid}`}>
          <Project data={project.data} />
        </Item>
      ))}
    </Container>
  )
}
