import styled, { keyframes } from "styled-components"
import { layout } from "styled-system"
import Link from "gatsby-link"
import Grid from "./grid"
import Text from "./text"

export const Container = styled(Grid.Item)`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  justify-self: center;
`
Container.defaultProps = {
  gridColumn: "main-start / main-end",
  mt: ["y.mobile.0.5", "y.desktop.1.0"],
  mb: ["y.mobile.5.5", "y.desktop.6.0"],
}

export const Label = styled(Text)`
  ${layout}
`
Label.defaultProps = {
  display: ["none", "none", "block"],
  fontSize: ["mobile.1", "desktop.1"],
  mx: "x.3",
}

const highlight = keyframes`
  from {
    transform: scale(0);
    opacity: 0.2;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

const textColor = keyframes`
  from {
    color: #fff;
  }
  to {
    color: #000;
  }
`

export const Tag = styled(Text)`
  position: relative;
  text-transform: lowercase;
  transform: perspective(1px) translateZ(0);
  cursor: pointer;
  transition: box-shadow 300ms ease, transform 300ms ease;

  &::before {
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem;
    z-index: -1;
    background: #fff;
    transform: scale(0);
    opacity: 0.2;
    transition-timing-function: ease-out;
    transition-duration: 0.3s;
    transition-property: transform;
    content: "";
  }

  &:hover {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
    animation: 300ms ease-out 1s forwards ${textColor};

    &::before {
      animation: 300ms ease-out 1s forwards ${highlight};
    }
  }

  &:active {
    color: #000;

    &::before {
      transform: scale(1);
      opacity: 1;
    }
  }
`
Tag.defaultProps = {
  as: Link,
  bg: "black.0",
  fontFamily: "mono",
  fontSize: ["mobile.-2", "desktop.-2"],
  color: "white",
  px: "1.6rem",
  py: "0.7rem",
  mr: ["x.1", "x.1", "x.3"],
  ml: ["x.1", "x.1", 0],
  my: ["y.mobile.0.5", "y.desktop.0.5"],
}
