import styled from "styled-components"
import Text from "./text"

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;

  /* background-color: rgba(0, 0, 0, 0.25); */
  opacity: 1;
  pointer-events: none;
`

export const Inner = styled(Text)`
  transform: translate3d(-50%, calc(-50% - 2.8rem), 0);

  span {
    display: inline;
    padding: 0.4rem;
    padding-right: 0;
    padding-left: 0;
    white-space: nowrap;
    background: #000;
    box-shadow: 0.4rem 0 0 #000, -0.5rem 0 0 #000;
    box-decoration-break: clone;
  }
`

Inner.defaultProps = {
  as: "p",
  fontFamily: "sansSerif",
  fontSize: ["mobile.2", "desktop.2"],
  lineHeight: ["mobile.1.5", "desktop.1.5"],
  textAlign: ["left", "left", "justify"],
  color: "white",
  px: "x.1",
}
