import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import { Container, Inner, Image, Title } from "./project.styles"
import FlyingText from "./flying-text"

export default ({ data }) => {
  const [mouseOver, setMouseOver] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: "40em" })

  return (
    <Container
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <Inner>
        <Image fluid={data.cover_image.fluid} alt={data.cover_image.alt} />
      </Inner>
      {!isMobile && <FlyingText active={mouseOver}>{data.name}</FlyingText>}
      {isMobile && <Title>{data.name}</Title>}
    </Container>
  )
}
