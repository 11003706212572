import styled from "styled-components"
import { compose, layout, space } from "styled-system"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import Grid from "./grid"

export const Container = styled(Grid.Item)``
Container.defaultProps = {
  gridColumn: ["hero-start / hero-end"],
  mt: 0,
  px: "x.2",
  pt: ["y.mobile.0.5", 0],
  pb: ["y.mobile.1.0", "y.desktop.0.5"],
  bg: "black.0",
}

export const VideoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 0;
  padding-bottom: 42.49%; /* 56.25%; */
`

export const Vimeo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: none;
  transform-origin: center center;
`

export const FallbackImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 1;
`
FallbackImg.defaultProps = {}

export const Controls = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`
