export const createColumnArray = (items = [], size = 1) => {
  const arr = new Array(size)
  for (let i = 0; i < size; i++) {
    arr[i] = new Array()
  }

  items.forEach((item, index) => {
    arr[index % size].push(item)
  })
  return arr
}

export default createColumnArray
