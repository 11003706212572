import React from "react"
import { Container, Label, Tag } from "./keywords.styles"

export default ({ data }) => {
  return (
    <Container>
      <Label>More:</Label>
      {data.map(({ node }) => (
        <Tag key={node.uid} to={`/portfolio/?filter=${node.uid}`}>
          {node.data.name}
        </Tag>
      ))}
    </Container>
  )
}
