import React, { useState } from "react"
import Img from "gatsby-image"
import { useHarmonicIntervalFn } from "react-use"
import { Container, Inner, Client } from "./clients.styles"
import createColumnArray from "../utils/createColumnArray"

const ClientCarousel = ({ data, delay, staggingDelay }) => {
  const [active, setActive] = useState(0)
  useHarmonicIntervalFn(() => setActive((active + 1) % data.length), delay)

  return data.map(({ node }, index) => (
    <Client
      key={node.id}
      className={active === index && "active"}
      delay={staggingDelay}
    >
      <Img
        fluid={node.data.image.fluid}
        alt={node.data.image.alt}
        style={{ maxWidth: "20rem", maxHeight: "11.2rem" }}
        imgStyle={{ objectFit: "contain" }}
      />
    </Client>
  ))
}

export default ({ data }) => {
  const items = createColumnArray(data, 3)

  return (
    <Container>
      {items.map((item, index) => {
        return (
          <Inner
            key={`column-${index}`}
            gridColumn={`client${index}-start / client${index}-end`}
          >
            <ClientCarousel
              data={item}
              delay={4000}
              staggingDelay={index * 150}
            />
          </Inner>
        )
      })}
    </Container>
  )
}
